@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('./assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: bold;
}


* {
  margin: 0px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}